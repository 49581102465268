import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { sendOpenUrl } from "../../utils/interactWithFlutter";
import { useUserContext } from "../../contexts/UserContext";
import { useTranslation } from "react-i18next";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
`;

const ModalContent = styled.div`
  background: ${({ theme }) => theme.backgroundSecondary};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 80%;
  width: 400px;
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-size: 0.9rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const MaintenanceModal = () => {
  const { state } = useUserContext();
  const { profile } = state;
  const [showModal, setShowModal] = useState(true);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { t } = useTranslation();
  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>{t("긴급 서버 점검 공지")}</ModalTitle>
        <p style={{ textAlign: "right" }}>2024.12.01 09:30</p>
        <p>
          {t(
            "현재 서버 점검이 진행 중이며, 계정 복구 작업이 완료되었습니다. 이제 정상화 작업에 들어가고 있으며, 약 30분 정도 소요될 것으로 예상됩니다."
          )}
        </p>
        <p>
          {t(
            "서버 이전 과정에서 발생한 문제로 인해 많은 불편을 드린 점 진심으로 사과드립니다. 현재 기술팀이 최선을 다해 문제를 해결하고 있으며, 고객 여러분의 소중한 데이터는 안전하게 보호되고 있습니다."
          )}
        </p>
        <p>
          {t(
            "업체 측에서는 문제를 해결하였으며, 이제 저희 측에서만 작업을 진행하면 됩니다. 주말이라 작업이 다소 지연된 점 진심으로 사과드리며, 조금만 더 기다려 주시면 빠른 시간 내에 정상 서비스를 제공할 수 있도록 하겠습니다."
          )}
        </p>
        <p>
          {t(
            "불편을 끼쳐 드려 다시 한번 깊이 사과드리며, 최대한 신속하게 서비스를 복구하겠습니다."
          )}
        </p>
        <p>{t("여러분의 이해와 협조에 감사드립니다.")}</p>
      </ModalContent>
    </ModalOverlay>
  );
};

export default MaintenanceModal;
