import React from "react";
import styled from "styled-components";
import { FiRefreshCw } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.body};
`;

const RefreshButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text};
`;

const StyledRefreshIcon = styled(FiRefreshCw)`
  font-size: 3rem;
  color: ${({ theme }) => theme.text};
`;

const LoadingText = styled.p`
  margin-top: 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
`;

const LoadingRefresh = () => {
  const { t } = useTranslation();
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <LoadingContainer>
      <RefreshButton onClick={handleRefresh}>
        <StyledRefreshIcon />
      </RefreshButton>
      <LoadingText>{t("loading_message")}</LoadingText>
    </LoadingContainer>
  );
};

export default LoadingRefresh;
