import styled from "styled-components";
import { ReactComponent as SubmitIcon } from "../../assets/icons/btn_submit.svg";

export const BoxShadow = styled.div`
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05), -2px -2px 4px rgba(0, 0, 0, 0.05),
    -2px 2px 4px rgba(0, 0, 0, 0.05), 2px -2px 4px rgba(0, 0, 0, 0.05);
`;

export const Button = styled(BoxShadow)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.6rem; /* 5vw -> 1.6rem */
  margin-bottom: 1.6rem; /* 5vw -> 1.6rem */
  width: 75%; /* 75vw -> 75% */
  height: 8.2rem; /* 25vw -> 8.2rem */
  background-color: white;
  border-radius: 20px;
  color: black;
`;

export const StyledForm = styled(BoxShadow)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.6rem; /* 5vw -> 1.6rem */
  margin-bottom: 1.6rem; /* 5vw -> 1.6rem */
  padding: 1.6rem; /* 5vw -> 1.6rem */
  width: 75%; /* 75vw -> 75% */
  background-color: ${({ theme }) => theme.buttonBackground};
  border-radius: 10px;
`;

export const StyledInput = styled.input`
  background: none;
  width: 60%;
  text-align: right;
  padding: 0.7rem 0; /* 2vw -> 0.7rem */
  font-size: 1rem; /* 3vw -> 1rem */
  font-family: "title", Arial, Helvetica, sans-serif;
  border: 0;
  caret-color: #cccccc;
  outline: none;

  &::placeholder {
    color: #cccccc;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 0.3rem; /* 1vw -> 0.3rem */
  text-align: left;
  position: relative;
  display: flex;
  border-bottom: 2px solid #cccccc;
  justify-content: space-between;
  height: 2.5rem;
`;

export const Label = styled.label`
  font-size: 1rem; /* 3vw -> 1rem */
  margin-top: 0.7rem; /* 2vw -> 0.7rem */
  display: block;
  width: 40%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Title = styled.p`
  font-size: 2rem; /* 7vw -> 2.3rem */
  margin: 7.1rem 0 0 0; /* 12vh -> 7.1rem */
  color: white;
  font-family: "title", Arial, Helvetica, sans-serif;
`;

export const SubmitContainer = styled.div`
  width: 3.3rem; /* 10vw -> 3.3rem */
  margin: 1.6rem auto 0 auto; /* 5vw -> 1.6rem */
`;

export const Submit = styled.img`
  width: 100%;
`;

export const BackButton = styled.img`
  position: absolute;
  top: 1rem;
  left: 2.7rem; /* 5vw -> 3.3rem */
  width: 2.7rem; /* 10vw -> 3.3rem */
`;

export const PhotoUploadButton = styled.img`
  width: 9.8rem; /* 30vw -> 9.8rem */
  height: 9.8rem; /* 30vw -> 9.8rem */
  margin-bottom: 1.6rem; /* 5vw -> 1.6rem */
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const InfoImage = styled.img`
  width: 1.6rem; /* 5vw -> 1.6rem */
  position: absolute;
  top: 0;
  right: 0;
`;

export const TemperatureIcon = styled.img`
  width: 1rem; /* 3vw -> 1rem */
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: ${({ theme }) => theme.body};
  padding: 2.3rem 1.6rem; /* 7vw 5vw -> 2.3rem 1.6rem */
  border-radius: 10px;
  text-align: center;
  font-size: 1.6rem; /* 5vw -> 1.6rem */
  width: 80%; /* 80vw -> 26.3rem */
`;

//레거시 혹시 몰라서 남겨놨습니다.
export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  transition: background-image 0.5s ease-in-out;
  @media (orientation: landscape) {
    background-position: center 0%;
  }
`;

export const SubmitIconStyled = styled(SubmitIcon)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  width: 65%;
  justify-content: right;
  gap: 1rem;
  padding: auto 1rem; /* 3vw -> 1rem */
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1rem; /* 3vw -> 1rem */
  position: relative;
  border-radius: 10px;
  transition: border-color 0.3s ease;
  margin: auto 0;
  border: 1px solid rgba(151, 191, 190, 0.5);
  background-color: ${({ isSelected, theme }) =>
    isSelected ? "rgba(151, 191, 190, 0.5)" : theme.buttonBackground};
  padding: ${({ isSelected }) =>
    isSelected ? "0" : "0.1rem 0.6rem"}; /* 0.5vw 2vw -> 0.1rem 0.7rem */
`;

export const RadioButton = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked + span {
    display: inline-block;
  }
`;

export const Icon = styled.span`
  display: none;
  font-size: 1rem; /* 3vw -> 1rem */
`;

export const DisclaimerText = styled.p`
  font-size: 0.9rem;
  font-family: "text", Arial, Helvetica, sans-serif;
  color: ${({ theme }) => theme.textSecondary};
  margin: 1vw 0;
  text-align: center;
`;
