import React from "react";
import styled from "styled-components";

const BackgroundContainer = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? props.top : 0)};
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.body};
  overflow: hidden;
  transition: background-color 0.5s linear;
`;

const ColoredSection = styled.div`
  content: "";
  position: absolute;
  top: 0;
  left: -5%;
  right: -5%;
  height: ${(props) => (props.height ? props.height : "10rem")};
  background-color: ${({ theme }) => theme.topColor};
  border-bottom-left-radius: 50% 30%;
  border-bottom-right-radius: 50% 30%;
  transform: scaleX(1.1);
`;

const Background = ({ top, height }) => {
  return (
    <BackgroundContainer top={top}>
      <ColoredSection height={height} />
    </BackgroundContainer>
  );
};

export default Background;
