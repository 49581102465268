import React, { createContext, useContext, useState } from "react";
import WelcomeDisplay from "../components/common/WelcomDisplay";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const showWelcomeToast = () => {
    const isFirstRecord = localStorage.getItem("isFirstRecord");
    if (isFirstRecord === "false") return;
    localStorage.setItem("isFirstRecord", "false");
    setIsClosing(false);
    setShowToast(true);

    // 4.5초 후에 closing 애니메이션 시작
    setTimeout(() => {
      setIsClosing(true);
      // 애니메이션이 완료된 후에 컴포넌트 제거
      setTimeout(() => {
        setShowToast(false);
      }, 500); // 애니메이션 지속 시간과 동일하게 설정
    }, 4500);
  };

  return (
    <ToastContext.Provider value={{ showWelcomeToast }}>
      {children}
      {showToast && <WelcomeDisplay isClosing={isClosing} />}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
