import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ko from "javascript-time-ago/locale/ko";
import { round, mini, canonical } from "javascript-time-ago/steps";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ko);

const hourFormatter = (value, locale, { now }) => {
  const diffInSeconds = Math.floor((now - value) / 1000);
  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const labels = locale === "ko" ? customLabelsKo : customLabelsEn;

  if (hours === 0) {
    return labels.minute.past.other.replace("{0}", remainingMinutes);
  } else if (remainingMinutes === 0) {
    return labels.hour.past.noMinutes.replace("{0}", hours);
  } else {
    return labels.hour.past.other
      .replace("{0}", hours)
      .replace("{1}", remainingMinutes);
  }
};

const dayHourFormatter = (value, locale, { now }) => {
  const diffInSeconds = Math.floor((now - value) / 1000);
  const hours = Math.floor(diffInSeconds / 3600);
  const days = Math.floor(hours / 24);
  const remainingHours = hours % 24;

  const labels = locale === "ko" ? customLabelsKo : customLabelsEn;

  if (days > 3) {
    return labels.day.past.other.replace("{0}", days);
  } else if (remainingHours === 0) {
    return labels.day.past.other.replace("{0}", days);
  } else {
    return labels.dayHour.past.other
      .replace("{0}", days)
      .replace("{1}", remainingHours);
  }
};

const customLabelsEn = {
  second: {
    past: {
      one: "{0}s ago",
      other: "{0}s ago",
    },
    future: {
      one: "in {0}s",
      other: "in {0}s",
    },
  },
  minute: {
    past: {
      one: "{0}m ago",
      other: "{0}m ago",
    },
    future: {
      one: "in {0}m",
      other: "in {0}m",
    },
  },
  hour: {
    past: {
      noMinutes: "{0}h ago",
      one: "{0}h {1}m ago",
      other: "{0}h {1}m ago",
    },
    future: {
      noMinutes: "in {0}h",
      one: "in {0}h {1}m",
      other: "in {0}h {1}m",
    },
  },
  dayHour: {
    past: {
      one: "{0}d {1}h ago",
      other: "{0}d {1}h ago",
    },
    future: {
      one: "in {0}d {1}h",
      other: "in {0}d {1}h",
    },
  },
  day: {
    past: {
      one: "{0}d ago",
      other: "{0}d ago",
    },
    future: {
      one: "in {0}d",
      other: "in {0}d",
    },
  },
  week: {
    past: {
      one: "{0}w ago",
      other: "{0}w ago",
    },
    future: {
      one: "in {0}w",
      other: "in {0}w",
    },
  },
  month: {
    past: {
      one: "{0}mo ago",
      other: "{0}mo ago",
    },
    future: {
      one: "in {0}mo",
      other: "in {0}mo",
    },
  },
  year: {
    past: {
      one: "{0}y ago",
      other: "{0}y ago",
    },
    future: {
      one: "in {0}y",
      other: "in {0}y",
    },
  },
};

const customLabelsKo = {
  second: {
    past: {
      one: "{0}초 전",
      other: "{0}초 전",
    },
    future: {
      one: "{0}초 후",
      other: "{0}초 후",
    },
  },
  minute: {
    past: {
      one: "{0}분 전",
      other: "{0}분 전",
    },
    future: {
      one: "{0}분 후",
      other: "{0}분 후",
    },
  },
  hour: {
    past: {
      noMinutes: "{0}시간 전",
      one: "{0}시간 {1}분 전",
      other: "{0}시간 {1}분 전",
    },
    future: {
      noMinutes: "{0}시간 후",
      one: "{0}시간 {1}분 후",
      other: "{0}시간 {1}분 후",
    },
  },
  dayHour: {
    past: {
      one: "{0}일 {1}시간 전",
      other: "{0}일 {1}시간 전",
    },
    future: {
      one: "{0}일 {1}시간 후",
      other: "{0}일 {1}시간 후",
    },
  },
  day: {
    past: {
      one: "{0}일 전",
      other: "{0}일 전",
    },
    future: {
      one: "{0}일 후",
      other: "{0}일 후",
    },
  },
  week: {
    past: {
      one: "{0}주 전",
      other: "{0}주 전",
    },
    future: {
      one: "{0}주 후",
      other: "{0}주 후",
    },
  },
  month: {
    past: {
      one: "{0}개월 전",
      other: "{0}개월 전",
    },
    future: {
      one: "{0}개월 후",
      other: "{0}개월 후",
    },
  },
  year: {
    past: {
      one: "{0}년 전",
      other: "{0}년 전",
    },
    future: {
      one: "{0}년 후",
      other: "{0}년 후",
    },
  },
};

TimeAgo.addLabels("en", "custom", customLabelsEn);
TimeAgo.addLabels("ko", "custom", customLabelsKo);

export const customStyle = {
  steps: [
    {
      minTime: 0,
      formatAs: "now",
    },
    {
      minTime: 60,
      formatAs: "minute",
    },
    {
      minTime: 60 * 60,
      format: hourFormatter,
    },
    {
      minTime: 60 * 60 * 24,
      format: dayHourFormatter,
    },
    {
      minTime: 60 * 60 * 24 * 7,
      formatAs: "week",
    },
    {
      minTime: 60 * 60 * 24 * 30,
      formatAs: "month",
    },
    {
      minTime: 60 * 60 * 24 * 365,
      formatAs: "year",
    },
  ],
  labels: "custom",
};
