import React, { createContext, useContext, useReducer, useEffect } from "react";
import apiClient from "../utils/api";

const UserContext = createContext();

const initialState = {
  profile: JSON.parse(localStorage.getItem("profile")) || null,
  babies: JSON.parse(localStorage.getItem("babies")) || [],
  selectedBaby: JSON.parse(localStorage.getItem("selectedBaby")) || null,
  recordUpdate: Boolean(localStorage.getItem("updateRecord")) || false,
  notificationSettings:
    JSON.parse(localStorage.getItem("notificationSettings")) || {},
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_PROFILE":
      localStorage.setItem("profile", JSON.stringify(action.payload));
      return { ...state, profile: action.payload };
    case "SET_BABIES":
      localStorage.setItem("babies", JSON.stringify(action.payload));
      return { ...state, babies: action.payload };
    case "SELECT_BABY":
      localStorage.setItem("selectedBaby", JSON.stringify(action.payload));
      return { ...state, selectedBaby: action.payload };
    case "UPDATE_BABY":
      const updatedBabies = state.babies.map((baby) =>
        baby.id === action.payload.id ? action.payload : baby
      );
      localStorage.setItem("babies", JSON.stringify(updatedBabies));
      return { ...state, babies: updatedBabies };
    case "DELETE_BABY":
      const remainingBabies = state.babies.filter(
        (baby) => baby.id !== action.payload
      );
      localStorage.setItem("babies", JSON.stringify(remainingBabies));
      localStorage.removeItem("selectedBaby");
      return {
        ...state,
        babies: remainingBabies,
        selectedBaby: remainingBabies.length > 0 ? remainingBabies[0] : null,
      };
    case "UPDATE_RECORD":
      localStorage.setItem("updateRecord", action.payload);
      return { ...state, recordUpdate: action.payload };
    case "SET_NOTIFICATION_SETTINGS":
      localStorage.setItem(
        "notificationSettings",
        JSON.stringify(action.payload)
      );
      return { ...state, notificationSettings: action.payload };
    case "UPDATE_NOTIFICATION_SETTING":
      const { babyId, type, settings } = action.payload;
      const babySettings = state.notificationSettings[babyId] || {};
      const updatedSettings = {
        ...state.notificationSettings,
        [babyId]: {
          ...babySettings,
          [type]: {
            ...{ time: "01:00", mode: "interval", enabled: false },
            ...babySettings[type],
            ...settings,
          },
        },
      };
      localStorage.setItem(
        "notificationSettings",
        JSON.stringify(updatedSettings)
      );
      return { ...state, notificationSettings: updatedSettings };
    case "UPDATE_BABY_MEASUREMENTS":
      const { babyId: measurementBabyId, height, weight } = action.payload;
      const updatedBabiesAfterMeasurement = state.babies.map((baby) => {
        if (baby.id === measurementBabyId) {
          return {
            ...baby,
            height: height !== 0 ? height : baby.height,
            weight: weight !== 0 ? weight : baby.weight,
          };
        }
        return baby;
      });
      localStorage.setItem(
        "babies",
        JSON.stringify(updatedBabiesAfterMeasurement)
      );
      return { ...state, babies: updatedBabiesAfterMeasurement };
    case "SET_BABY_DATA_VALID":
      return { ...state, isBabyDataValid: action.payload };

    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    const storedProfile = localStorage.getItem("profile");
    const storedBabies = localStorage.getItem("babies");
    const storedSelectedBaby = localStorage.getItem("selectedBaby");
    const storedNotificationSettings = localStorage.getItem(
      "notificationSettings"
    );

    if (storedProfile) {
      dispatch({ type: "SET_PROFILE", payload: JSON.parse(storedProfile) });
    }
    if (storedBabies) {
      dispatch({ type: "SET_BABIES", payload: JSON.parse(storedBabies) });
    }
    if (storedSelectedBaby) {
      dispatch({
        type: "SELECT_BABY",
        payload: JSON.parse(storedSelectedBaby),
      });
    }
    if (storedNotificationSettings) {
      dispatch({
        type: "SET_NOTIFICATION_SETTINGS",
        payload: JSON.parse(storedNotificationSettings),
      });
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "selectedBaby") {
        dispatch({
          type: "SELECT_BABY",
          payload: JSON.parse(event.newValue),
        });
      }
      if (event.key === "updateRecord") {
        dispatch({
          type: "UPDATE_RECORD",
          payload: Boolean(event.newValue),
        });
      }
      // noti 설정도 탭 간에 공유 되어야하는지 고민
      // if (event.key === "notificationSettings") {
      //   dispatch({
      //     type: "SET_NOTIFICATION_SETTINGS",
      //     payload: JSON.parse(event.newValue),
      //   });
      // }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const checkProfile = async () => {
    try {
      const response = await apiClient.get("user");
      if (response.data.email) {
        dispatch({ type: "SET_PROFILE", payload: response.data });
        await checkBaby();
      } else {
        dispatch({ type: "SET_PROFILE", payload: null });
      }
    } catch (error) {
      console.error("Failed to fetch profile:", error);
      dispatch({ type: "SET_PROFILE", payload: null });
    }
  };

  const checkBaby = async () => {
    try {
      const response = await apiClient.get("userbaby");
      if (response.data.length > 0) {
        dispatch({ type: "SET_BABIES", payload: response.data });

        const storedSelectedBaby = JSON.parse(
          localStorage.getItem("selectedBaby")
        );
        let newSelectedBaby;

        if (response.data.length === 1) {
          newSelectedBaby = response.data[0];
        } else if (!storedSelectedBaby) {
          newSelectedBaby = response.data[0];
        } else {
          newSelectedBaby =
            response.data.find((baby) => baby.id === storedSelectedBaby.id) ||
            response.data[0];
        }
        dispatch({ type: "SELECT_BABY", payload: newSelectedBaby });
        localStorage.setItem("selectedBaby", JSON.stringify(newSelectedBaby));
      } else {
        dispatch({ type: "SET_BABIES", payload: [] });
        dispatch({ type: "SELECT_BABY", payload: null });
        localStorage.removeItem("babies");
        localStorage.removeItem("selectedBaby");
      }
    } catch (error) {
      console.error("Failed to fetch baby info:", error);
    }
  };

  const updateBabyMeasurements = (babyId, height, weight) => {
    dispatch({
      type: "UPDATE_BABY_MEASUREMENTS",
      payload: { babyId, height, weight },
    });

    if (state.selectedBaby && state.selectedBaby.id === babyId) {
      const updatedSelectedBaby = {
        ...state.selectedBaby,
        height: height !== 0 ? height : state.selectedBaby.height,
        weight: weight !== 0 ? weight : state.selectedBaby.weight,
      };
      dispatch({ type: "SELECT_BABY", payload: updatedSelectedBaby });
      localStorage.setItem("selectedBaby", JSON.stringify(updatedSelectedBaby));
    }
  };

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
        checkProfile,
        checkBaby,
        updateBabyMeasurements,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
