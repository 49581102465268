import { useEffect } from "react";

const useDynamicFontSize = () => {
  useEffect(() => {
    const updateFontSize = () => {
      const width = window.innerWidth;
      let fontSize;

      // 폰트 크기를 더 세밀하게 조정하고 최대 크기를 제한
      if (width >= 1200) {
        fontSize = `${16 + (width - 1200) * 0.005}px`; // 1200px 이상일 때
      } else if (width >= 768) {
        fontSize = `${14 + (width - 768) * 0.007}px`; // 768px 이상 1200px 미만일 때
      } else {
        fontSize = `${12 + (width - 320) * 0.01}px`; // 320px 이상 768px 미만일 때
      }

      // 최대 폰트 크기 제한
      const maxFontSize = 16; // 최대 폰트 크기(px)
      if (parseFloat(fontSize) > maxFontSize) {
        fontSize = `${maxFontSize}px`;
      }

      document.documentElement.style.fontSize = fontSize;
    };

    window.addEventListener("resize", updateFontSize);
    updateFontSize(); // 초기 호출

    return () => window.removeEventListener("resize", updateFontSize);
  }, []);
};

export default useDynamicFontSize;
