// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en.json";
import translationKO from "./locales/ko.json";
import translationJA from "./locales/ja.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
  ja: {
    translation: translationJA,
  },
};

i18n
  .use(LanguageDetector) // LanguageDetector 추가
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", // 설정된 언어가 없을 때 사용할 기본 언어
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    // detection: {
    //   // 옵션을 통해 언어 감지 방법을 설정할 수 있습니다.
    //   order: [
    //     "querystring",
    //     "cookie",
    //     "localStorage",
    //     "navigator",
    //     "htmlTag",
    //     "path",
    //     "subdomain",
    //   ],
    //   caches: ["localStorage", "cookie"],
    // },
  });

export default i18n;
