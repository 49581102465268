import React, { useState } from "react";
import styled from "styled-components";
import toast from "react-hot-toast";
import axios from "axios"; // axios 직접 import
import { useUserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  max-width: 38.7rem;
  margin: auto;
  box-sizing: border-box;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Title = styled.h2`
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between; /* 양쪽 정렬 */
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const BackButton = styled(Button)`
  background-color: #6c757d;

  &:hover {
    background-color: #5a6268;
  }
`;

const ContactForm = () => {
  const { t } = useTranslation();
  const { state } = useUserContext();
  const { profile } = state;
  const [email, setEmail] = useState(profile?.email || "");
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email || !content) {
      toast.error(t("모든 필드를 작성해주세요."));
      return;
    }

    // 새로운 axios 인스턴스 생성
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    axiosInstance
      .post("/csc", {
        email: email,
        content: content,
      })
      .then((response) => {
        console.log("SUCCESS!", response.data);
        toast.success(t("문의가 성공적으로 전송되었습니다."));
        setContent("");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        toast.error(t("문의 전송에 실패했습니다. 다시 시도해주세요."));
      });
  };

  return (
    <ContactFormContainer>
      <Title>{t("문의하기")}</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="email"
          placeholder={t("답변 받을 이메일을 입력해주세요")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextArea
          rows="5"
          placeholder={t("문의 내용을 입력해주세요")}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />
        <ButtonContainer>
          <BackButton type="button" onClick={() => navigate(-1)}>
            {t("뒤로가기")}
          </BackButton>
          <Button type="submit">{t("보내기")}</Button>
        </ButtonContainer>
      </Form>
      <p>{t("답변은 상단에 적으신 이메일로 발송될 예정입니다.")}</p>
    </ContactFormContainer>
  );
};

export default ContactForm;
