import React, { useState, lazy, Suspense, useEffect } from "react";
import styled from "styled-components";
import { RefreshCw, BarChart2 } from "lucide-react";
import notification from "../../assets/icons/btn_notification_default.png";
import backIcon from "../../assets/icons/btn_back.png";
import trashIcon from "../../assets/icons/btn_trashcan_white.png";
import { Title } from "./CommonComponents";
import { track } from "@amplitude/analytics-browser";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const NotiSettingModal = lazy(() => import("../Etc/NotiSettingModal"));

const HeaderContainer = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  max-width: 38.7rem;
  width: 100%;
`;

const CustomTitle = styled(Title)`
  margin: 0;
  font-size: 1.7rem;
`;

const BackButton = styled.button`
  background: url(${backIcon}) no-repeat center center;
  background-size: contain;
  border: none;
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 2.3rem;
`;

const Spacer = styled.div`
  width: 1.5rem;
`;

const NotificationIcon = styled.div`
  width: 2rem;
  height: 2rem;
  background: url(${notification}) no-repeat center center;
  background-size: contain;
  aspect-ratio: 1 / 1;
`;

const TrashButton = styled.button`
  background: url(${trashIcon}) no-repeat center center;
  background-size: contain;
  border: none;
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 2rem;
`;

const RefreshIcon = styled(RefreshCw)`
  width: 2rem;
  height: 2rem;
  stroke-width: 3;
  color: rgba(255, 255, 255, 0.7);
`;

const StatIcon = styled(BarChart2)`
  width: 2rem;
  height: 2rem;
  stroke-width: 3;
  color: rgba(255, 255, 255, 0.7);
`;

const Header = ({
  text,
  showBackButton = false,
  showDeleteButton = false,
  onDeleteClick,
  handleRefresh,
  showRefreshIcon = true,
  showStatIcon = true,
  statIconRef,
  notificationIconRef,
}) => {
  const { t } = useTranslation();
  const [isNotiModalOpen, setIsNotiModalOpen] = useState(false);
  const navigate = useNavigate();
  const [shake, setShake] = useState(false);
  const [isLatestNotice, setIsLatestNotice] = useState(true);

  useEffect(() => {
    const lastSeenNoticeId = localStorage.getItem("lastSeenNoticeId");
    const latestNoticeId = 3; // 최신 공지사항 ID를 상수로 설정

    if (
      latestNoticeId &&
      (!lastSeenNoticeId || parseInt(lastSeenNoticeId) < latestNoticeId)
    ) {
      setShake(true);
      setIsLatestNotice(false);
    } else {
      setIsLatestNotice(true);
    }
  }, []);

  const handleAlarmClick = () => {
    track("notification-button-clicked");
    setIsNotiModalOpen(true);
  };

  const handleCloseNotiModal = () => {
    setIsNotiModalOpen(false);
  };

  const handleRefreshToast = () => {
    track("refresh-button-clicked");
    handleRefresh();
    toast.success(t("정상적으로 업데이트 됐습니다."));
  };

  const handleStatClick = () => {
    navigate("/stat");
  };

  return (
    <>
      <HeaderContainer>
        {showBackButton && <BackButton onClick={() => window.history.back()} />}
        {showBackButton && <Spacer />}
        <CustomTitle>{t(text)}</CustomTitle>
        {showDeleteButton ? (
          <>
            <TrashButton onClick={onDeleteClick} />
            <Spacer />
          </>
        ) : text === "분석 결과" ? (
          <Spacer />
        ) : (
          <div style={{ display: "flex" }}>
            {showRefreshIcon && (
              <RefreshIcon
                style={{ marginRight: "1rem" }}
                onClick={handleRefreshToast}
              />
            )}
            {showStatIcon && (
              <StatIcon
                {...(statIconRef && { ref: statIconRef })}
                style={{ marginRight: "1rem" }}
                onClick={handleStatClick}
              />
            )}

            <NotificationIcon
              isLatest={isLatestNotice}
              shake={shake}
              {...(notificationIconRef && { ref: notificationIconRef })}
              onClick={handleAlarmClick}
            />
          </div>
        )}
      </HeaderContainer>
      {isNotiModalOpen && (
        <Suspense fallback={<div>{t("로딩 중...")}</div>}>
          <NotiSettingModal onClose={handleCloseNotiModal} />
        </Suspense>
      )}
    </>
  );
};

export default Header;
