import React from "react";
import { PartyPopper, CalendarCheck } from "lucide-react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const Container = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  background: linear-gradient(
    135deg,
    rgba(151, 191, 190, 0.15) 0%,
    rgba(151, 191, 190, 0.05) 100%
  );
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
  z-index: 1000;
  animation: ${({ isClosing }) => (isClosing ? slideOut : slideIn)} 0.5s
    ease-in-out forwards;

  @media (max-width: 380px) {
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 1rem;

  @media (max-width: 380px) {
    padding: 0.75rem;
  }
`;

const TopBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: ${({ theme }) => theme.topColor};
`;

const WelcomeMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Title = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
  margin: 0;

  @media (max-width: 380px) {
    font-size: 1rem;
  }
`;

const InfoBox = styled.div`
  background: ${({ theme }) => theme.topColor};
  color: white;
  border-radius: 0.75rem;
  padding: 0.75rem;
  margin: 0.5rem 0 0.75rem 0;
`;

const InfoContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const InfoText = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;

  span {
    font-weight: 700;
  }

  @media (max-width: 380px) {
    font-size: 0.8125rem;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0 0.25rem;
`;

const ProgressBar = styled.div`
  flex: 1;
  height: 4px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 999px;
`;

const Progress = styled.div`
  width: 14%;
  height: 100%;
  background: ${({ theme }) => theme.topColor};
  border-radius: 999px;
`;

const ProgressText = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => (theme.isDarkMode ? theme.text : theme.topColor)};

  @media (max-width: 380px) {
    font-size: 0.6875rem;
  }
`;

const StyledPartyPopper = styled(PartyPopper)`
  color: ${({ theme }) => (theme.isDarkMode ? theme.text : theme.topColor)};
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledCalendarCheck = styled(CalendarCheck)`
  color: white;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
`;

const WelcomeDisplay = ({ isClosing }) => {
  const { t } = useTranslation();
  return (
    <Container isClosing={isClosing}>
      <Content>
        <TopBorder />
        <WelcomeMessage>
          <StyledPartyPopper />
          <Title>{t("첫 육아기록을 시작했어요")}</Title>
        </WelcomeMessage>
        <InfoBox>
          <InfoContent>
            <StyledCalendarCheck />
            <InfoText>
              {t("7일의 기록이 모이면")}
              <br />
              <span>{t("7일 뒤, 아기의 특별한 분석리포트로")}</span>
              <br />
              {t("만나보실 수 있어요")}
            </InfoText>
          </InfoContent>
        </InfoBox>
        <ProgressContainer>
          <ProgressBar>
            <Progress />
          </ProgressBar>
          <ProgressText>1/7</ProgressText>
        </ProgressContainer>
      </Content>
    </Container>
  );
};

export default WelcomeDisplay;
