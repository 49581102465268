import React from "react";
import { createGlobalStyle } from "styled-components";
import { useTranslation } from "react-i18next";
import subtitle from "../assets/fonts/AppleSDGothicNeoB.woff2";
import text from "../assets/fonts/AppleSDGothicNeoL.woff2";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "title";
    src: url(${({ language }) => (language === "ja" || language === "ja-JP" ) ? subtitle: "https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/Cafe24Ssurround.woff"}) format("woff");
    font-weight: normal;
    font-style: bold;
    font-display: swap;
  }

  @font-face {
    font-family: "subtitle";
    src: url(${subtitle}) format("woff2");
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: "text";
    src: url(${text}) format("woff2");
    font-weight: 300;
    font-display: swap;
  }

  body {
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.3s linear;
  }

  body::-webkit-scrollbar {
    display: none;
  }
  * {
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-family: 'subtitle', Arial, sans-serif;
  }
  input {
    -webkit-user-select: text !important;
    background-color: ${({ theme }) => theme.inputBackground};
    color: ${({ theme }) => theme.text};
  }

  textarea {
    -webkit-user-select: text !important;
    background-color: ${({ theme }) => theme.inputBackground};
    color: ${({ theme }) => theme.text};
  }
  .custom-datepicker-popup {
    max-width: 90vw !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    overflow: auto !important;
    box-sizing: border-box !important;
    font-size: 12px !important;
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    & > .ant-picker-panel-container {
      max-width: 100%;
      background-color: ${({ theme }) => theme.body};

    & > div,
    & > div > div,
    & > div > div > div,
    & > div > div > div > div,
    & > div > div > div > div > div.ant-picker-panel,
    & > div > div > div > div > div.ant-picker-footer,
    & > div > div > div > div > div.ant-picker-panel > div,
    & > div > div > div > div > div.ant-picker-panel > div > div.ant-picker-date-panel,
    & > div > div > div > div > div.ant-picker-panel > div > div.ant-picker-time-panel,
    & > div > div > div > div > div.ant-picker-footer > ul {
      width: 100% !important;
      font-size: 12px;
      box-sizing: border-box;
      background-color: ${({ theme }) => theme.body};
      color: ${({ theme }) => theme.text};
    }
    }
  }

  .ant-picker-datetime-panel {
    display: flex;
    .ant-picker-time-panel {
      flex: 3 !important;
    }
    .ant-picker-date-panel {
      flex: 7 !important;
    }
  }

  :root {
    --rsbs-bg: ${({ theme }) => theme.body};
    --rsbs-handle-bg: ${({ theme }) => theme.text};
  }
`;

const GlobalStyleComponent = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return <GlobalStyle language={language} />;
};

export default GlobalStyleComponent;
