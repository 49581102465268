import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import LanguageSelectionModal from "../components/common/LanguageSelectionModal";

const Main = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  line-height: 1.6;
  padding: 4.9rem;
`;
const LanguageButton = styled.div`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  margin-bottom: 2rem;
  border: 1px solid black;
  border-radius: 30px;
  padding: 0 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
`;
const PrivacyPolicy = () => {
  const { t, i18n } = useTranslation();
  const [showLanguageModal, setShowLanguageModal] = useState(false);

  const handleLanguageButtonClick = (e) => {
    e.preventDefault();
    setShowLanguageModal(true);
  };

  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode);
    localStorage.setItem("language", languageCode);
    localStorage.setItem("language_changed", Date.now());
  };
  const getLanguageName = (code) => {
    const languages = {
      ko: "한국어",
      "ko-KR": "한국어",
      "ja-JP": "日本語",
      "en-US": "English",
      en: "English",
      ja: "日本語",
    };
    return languages[code] || code;
  };
  return (
    <Main>
      <ButtonContainer>
        <p style={{ margin: "0 2rem" }}>Language</p>
        <LanguageButton
          onClick={handleLanguageButtonClick}
          onTouchStart={handleLanguageButtonClick}
          onMouseDown={(e) => e.preventDefault()}
        >
          {getLanguageName(i18n.language)}
        </LanguageButton>
      </ButtonContainer>
      <h1>{t("개인정보 처리방침")}</h1>
      <p>
        {t("본 개인정보 처리방침은 당사의 개인정보 보호 정책을 설명합니다.")}
      </p>

      <h2>{t("1. 수집하는 개인정보 항목")}</h2>
      <p>{t("당사는 다음과 같은 개인정보를 수집합니다.")}</p>
      <ul>
        <li>{t("이메일 주소")}</li>
      </ul>

      <h2>{t("2. 개인정보의 수집 및 이용 목적")}</h2>
      <p>{t("수집한 개인정보는 다음의 목적을 위해 사용됩니다.")}</p>
      <ul>
        <li>{t("회원 구분")}</li>
      </ul>
      <p>{t("당사는 수집한 개인정보를 마케팅 및 광고에 활용하지 않습니다.")}</p>

      <h2>{t("3. 개인정보의 보유 및 이용 기간")}</h2>
      <p>{t("당사는 법령에 따른 개인정보 보유 및 이용 기간을 준수합니다.")}</p>
      <ul>
        <li>
          {t(
            "온라인/모바일 서비스 제공을 위해 수집한 회원가입 정보: 회원탈퇴 시까지"
          )}
        </li>
        <li>{t("개별적으로 이용자의 동의를 받은 경우: 동의를 받은 기간")}</li>
        <li>
          {t(
            "회사와 이용자 간에 민원, 소송 등 분쟁이 발생한 경우: 분쟁이 해결될 때까지"
          )}
        </li>
      </ul>

      <h2>{t("4. 개인정보 열람, 정정, 삭제 요구의 권리")}</h2>
      <p>
        {t(
          "정보주체는 언제든지 등록되어 있는 자신 혹은 해당 미성년자 이용자의 개인정보를 열람하거나 정정할 수 있고 삭제를 요구할 수 있습니다. 정보주체가 개인정보 열람, 정정 및 삭제를 하고자 할 경우에는 직접 열람, 정정 및 삭제하거나 또는 회사의 개인정보 보호 책임자 및 담당자에게 서면 또는 전자우편으로 연락하는 경우 지체없이 조치하도록 하겠습니다. 다만, 정보주체는 다른 법령에서 그 개인정보가 수집대상으로 명시되어 있는 경우에는 삭제를 요구할 수 없으며, 개인정보를 정정・삭제하는 경우 일부 또는 전부의 서비스 이용이 불가능할 수 있습니다."
        )}
      </p>

      <h2>{t("5. 개인정보 처리정지 요구의 권리")}</h2>
      <p>
        {t(
          "정보주체는 언제든지 등록되어 있는 자신 혹은 미성년자 이용자의 개인정보의 처리정지를 요구할 수 있습니다. 개인정보 처리의 정지를 원하는 경우 회사의 개인정보 보호 책임자 및 담당자에게 서면 또는 전자우편으로 연락하는 경우 지체 없이 조치하도록 하겠습니다. 다만, 회사는 관련 법령에 따라 이용자의 처리정지 요구에도 불구하고 계속하여 개인정보를 처리할 수 있으며, 이러한 경우에는 지체 없이 그 내용을 정보주체에게 알리도록 하겠습니다."
        )}
      </p>

      <h2>{t("6. 개인정보 수집, 이용, 제공에 대한 동의 철회의 권리")}</h2>
      <p>
        {t(
          "정보주체는 언제든지 등록되어 있는 자신 혹은 해당 미성년자 이용자의 개인정보의 수집, 이용, 제공에 대한 동의를 철회할 수 있습니다. 이를 위하여 정보주체는 [회원탈퇴] 절차를 진행할 수 있고, 또는 회사의 개인정보 보호 책임자 및 담당자에게 서면 또는 전자우편으로 연락하여 철회를 신청할 수 있습니다. 이러한 경우 회사는 본인 확인 절차를 거친 후 개인정보의 삭제 등 필요한 조치를 하겠습니다."
        )}
      </p>

      <h2>{t("7. 처리하는 개인정보 항목")}</h2>
      <p>{t("회원가입(제휴사 계정)")}</p>
      <ul>
        <li>{t("구글: 이메일")}</li>
        <li>{t("애플: 이메일")}</li>
        <li>{t("카카오: 이메일")}</li>
        <li>{t("네이버: 이메일")}</li>
      </ul>

      <h2>{t("8. 개인정보의 파기")}</h2>
      <p>
        ①{" "}
        {t(
          "회사는 개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다."
        )}
      </p>
      <p>
        ②{" "}
        {t(
          "정보주체가 동의한 개인정보 보유 기간이 경과하거나 처리목적이 달성되었음에도 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우, 법령에 따라 해당 개인정보를 보유합니다."
        )}
      </p>
      <p>③ {t("개인정보 파기 절차 및 방법은 다음과 같습니다.")}</p>
      <p>
        <strong>{t("파기 절차")}</strong>
        <br />
        {t(
          "수집·이용목적이 달성된 개인정보는 지체없이 파기되며, 관련 법령에 따라 보관되어야 할 경우 별도의 DB에 옮겨져 내부 규정 및 관련 법령을 준수하여 일정기간(개인정보의 처리 및 보유기간 참조) 동안 안전하게 보관된 후 지체없이 파기됩니다. 이때, DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고 다른 목적으로 이용하지 않습니다."
        )}
      </p>
      <p>
        <strong>{t("파기 방법")}</strong>
        <br />
        {t(
          "전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이에 기록, 출력된 개인정보는 분쇄하거나 소각을 통하여 파기합니다."
        )}
      </p>

      <h2>{t("9. 개인정보의 안전성 확보 조치")}</h2>
      <p>
        {t(
          "회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 위조, 변조 또는 훼손되지 않도록 개인정보의 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다."
        )}
      </p>
      <ul>
        <li>
          <strong>{t("내부관리계획의 수립 및 시행")}</strong>:{" "}
          {t(
            "개인정보의 안전한 처리를 위하여 그렙 개인정보보호 내부관리계획 수립 및 시행하고 있습니다."
          )}
        </li>
        <li>
          <strong>{t("개인정보 취급 담당자의 최소화 및 교육")}</strong>:{" "}
          {t(
            "개인정보를 취급하는 담당자를 지정하고 관리하고 있으며 취급직원을 대상으로 안전한 관리를 위한 교육을 실시하고 있습니다."
          )}
        </li>
        <li>
          <strong>{t("개인정보에 대한 접근 제한")}</strong>:{" "}
          {t(
            "개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있습니다."
          )}
        </li>
        <li>
          <strong>{t("접속기록의 보관")}</strong>:{" "}
          {t(
            "개인정보처리시스템에 접속한 기록을 최소 2년 이상 보관, 관리하고 있습니다."
          )}
        </li>
        <li>
          <strong>{t("개인정보의 암호화")}</strong>:{" "}
          {t("개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다.")}
        </li>
        <li>
          <strong>{t("보안 프로그램 설치 및 주기적 점검")}</strong>:{" "}
          {t(
            "해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적으로 점검하고 있습니다."
          )}
        </li>
      </ul>

      <h2>{t("개인정보 보호 책임자 및 개인정보 침해 문의")}</h2>
      <p>
        ①{" "}
        {t(
          "회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만 처리 및 피해구제 등을 위해 아래와 같이 개인정보 보호 담당 부서를 지정하고 있습니다. 고객의 개인정보와 관련한 문의 사항이 있으면 아래의 개인정보 보호 책임자에게 연락 주시기 바랍니다."
        )}
      </p>
      <p>{t("부서명: 데이터센터")}</p>
      <p>{t("담당자: 이찬호(센터장)")}</p>
      <p>{t("이메일: readyupltd@gmail.com")}</p>
      <p>
        ②{" "}
        {t(
          "기타 개인정보 침해에 대한 피해 구제, 상담은 아래의 기관에 문의하실 수 있습니다."
        )}
      </p>
      <ul>
        <li>
          {t(
            "개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)"
          )}
        </li>
        <li>
          {t("개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)")}
        </li>
        <li>{t("대검찰청 사이버수사과 : (국번없이) 1301 (www.spo.go.kr)")}</li>
        <li>
          {t("경찰청 사이버수사국 : (국번없이) 182 (cyberbureau.police.go.kr)")}
        </li>
      </ul>

      <h2>{t("개인정보 처리방침의 변경")}</h2>
      <p>
        {t(
          "회사의 개인정보 처리방침은 법령 및 지침의 변경과 회사의 약관 및 내부 정책에 따라 변경될 수 있으며 이를 개정하는 경우, 회사는 변경사항에 대해 「개인정보 보호법」 제30조 및 동법 시행령 제31조에 따라 개정 내용을 회사 홈페이지 또는 전자우편을 통해 공개하겠습니다."
        )}
      </p>
      <p>{t("게시일: 2024년 6월 19일")}</p>
      <p>{t("적용일: 2024년 6월 19일")}</p>
      <LanguageSelectionModal
        isOpen={showLanguageModal}
        onDismiss={() => setShowLanguageModal(false)}
        onSelectLanguage={handleLanguageChange}
      />
    </Main>
  );
};

export default PrivacyPolicy;
