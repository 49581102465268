import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import styled, { useTheme } from "styled-components";
import { track } from "@amplitude/analytics-browser";
import { useUserContext } from "../contexts/UserContext";
import Header from "../components/common/Header";
import Background from "../components/common/Background";
import galleryActiveLight from "../assets/images/screenSwitchWhite1.png";
import calendarActiveLight from "../assets/images/screenSwitchWhite2.png";
import galleryActiveDark from "../assets/images/screenSwitchDark1.png";
import calendarActiveDark from "../assets/images/screenSwitchDark2.png";
import { useTranslation } from "react-i18next";
import setDateToMidnight from "../utils/dateToMidnight";
import { differenceInDays } from "date-fns";

const HeaderContainer = styled.div`
  padding: 0 1.64rem 0 1.64rem;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.49rem;
  position: relative;
`;

const TabImage = styled.img`
  width: 100%;
`;

const HiddenButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 41%;
  height: 3.94rem;
  font-size: 1.4rem;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  color: ${({ theme }) => theme.text};
`;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.body};
`;

const CheckDays = lazy(() => import("../components/Report/CheckDays"));
const NewReport = lazy(() => import("../components/Report/NewReport"));

const Report = () => {
  const [activeTab, setActiveTab] = useState("gallery");
  const theme = useTheme();
  const { state } = useUserContext();
  const { selectedBaby } = state;
  const [timeZone, setTimeZone] = useState("");
  const [refreshFunction, setRefreshFunction] = useState(null);
  const { t } = useTranslation();
  const [daysSinceBirth, setDaysSinceBirth] = useState(null);

  useEffect(() => {
    track("report-page-view");
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    if (selectedBaby && selectedBaby.babyBirthday) {
      const dob = setDateToMidnight(new Date(selectedBaby.babyBirthday));
      const today = setDateToMidnight(new Date());
      const daysDifference = differenceInDays(today, dob);
      const daysSince =
        daysDifference >= 0 ? daysDifference + 1 : daysDifference;
      setDaysSinceBirth(daysSince);
    }
  }, []);

  const handleRefresh = useCallback(() => {
    if (refreshFunction) {
      refreshFunction();
    }
  }, [refreshFunction]);

  const setRefreshCallback = useCallback((callback) => {
    setRefreshFunction(() => callback);
  }, []);

  const getCurrentTabImage = () => {
    const isDarkMode = theme.body === "#363537";
    if (activeTab === "gallery") {
      return isDarkMode ? galleryActiveDark : galleryActiveLight;
    } else {
      return isDarkMode ? calendarActiveDark : calendarActiveLight;
    }
  };

  if (!timeZone) return <div>Loading...</div>;

  return (
    <>
      <Background height={"20rem"} />
      <ContentContainer>
        <HeaderContainer>
          <Header
            text="건강 분석"
            showRefreshIcon={true}
            handleRefresh={handleRefresh}
          />
        </HeaderContainer>
        <TabContainer>
          <TabImage src={getCurrentTabImage()} alt="탭 전환 버튼" />
          <HiddenButton
            onClick={() => setActiveTab("gallery")}
            style={{ left: "7.7%", top: "2vw" }}
            isActive={activeTab === "gallery"}
          >
            {t("새로운 분석")}
          </HiddenButton>
          <HiddenButton
            onClick={() => setActiveTab("calendar")}
            style={{ right: "7.7%", top: "2vw" }}
            isActive={activeTab === "calendar"}
          >
            {t("기존 분석")}
          </HiddenButton>
        </TabContainer>
        <Container>
          {activeTab === "gallery" ? (
            <Suspense fallback={<div>{t("로딩 중...")}</div>}>
              <NewReport
                babyId={selectedBaby.id}
                timeZone={timeZone}
                babyAgeInDays={daysSinceBirth}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<div>{t("로딩 중...")}</div>}>
              <CheckDays
                babyId={selectedBaby.id}
                timeZone={timeZone}
                onRefresh={setRefreshCallback}
              />
            </Suspense>
          )}
        </Container>
      </ContentContainer>
    </>
  );
};

export default Report;
