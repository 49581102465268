import { useEffect } from "react";

const useGlobalAutoScroll = () => {
  useEffect(() => {
    const handleFocus = (event) => {
      event.target.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const addListeners = (elements) => {
      elements.forEach((element) => {
        if (!element.dataset.autoScrollListener) {
          element.addEventListener("focus", handleFocus);
          element.dataset.autoScrollListener = "true";
        }
      });
    };

    const removeListeners = (elements) => {
      elements.forEach((element) => {
        element.removeEventListener("focus", handleFocus);
        delete element.dataset.autoScrollListener;
      });
    };

    // Initial setup
    const initialInputs = document.querySelectorAll("input, textarea");
    addListeners(initialInputs);

    // Set up MutationObserver
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          const newInputs = mutation.target.querySelectorAll("input, textarea");
          addListeners(newInputs);
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function
    return () => {
      observer.disconnect();
      const allInputs = document.querySelectorAll("input, textarea");
      removeListeners(allInputs);
    };
  }, []);
};

export default useGlobalAutoScroll;
